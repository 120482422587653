import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import DoveSiamo from '../components/dove-siamo'
import NavMenu from '../components/nav-menu'
import DocumentiUtili from '../components/documenti-utili'
import navigation from '../utility/navigation'
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

class Accettazione extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Chi siamo"},{"title":"Accetazione"}];
    const data = this.props.data.contentfulContenutoGenerico;
    const staff = data.staff || []; 
    const tree = this.props.data.navigationJson;
    const chisiamo = navigation.getPageInfo("/chi-siamo/",tree.header);


    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Accettazione"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <h1>Accettazione</h1>
              {!!data.abstract && 
                <div className="bodytext" dangerouslySetInnerHTML={{ __html: data.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
              <div className="row">
                {staff.map((item,index) => {
                  let thumb = "/images/staff-placeholder.png";
                  if (item.immagine) {
                    thumb = item.immagineLista.file.url  
                  }
                  return (
                    <div key={index} className="col-6 col-lg-3">
                      <div className="staff-list staff-list--bottom">
                        <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name"><img src={thumb} alt={`${item.cognome} ${item.nome}`} className="staff-list__img" /></Link>
                        <div className="staff-list__detail">
                          <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name">{item.nome} {item.cognome}</Link>
                          <div className="staff-list__role">{item.ruolo}</div>
                        </div>
                      </div>
                    </div> 
                   )
                  })
                } 
              </div>
            </div>
            <div className="col-md-4">
              <NavMenu section="Chi siamo" active="/accettazione/" data={chisiamo.children}/>
              <DoveSiamo/>
              {!!data.attachment &&
                <DocumentiUtili data={data.attachment}/>
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Accettazione;

export const query = graphql`
query AccettazioneQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "xLa5y10eoCeggqoCcuiKq"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
    staff {
      nome
      cognome
      friendlyUrl
      ruolo
      immagineLista {
        file {
          url
        }
      }
      immagine {
        file {
          url
        }
      }
    }
  }
  navigationJson{
    header{
      name
      link
      children{
        name
        link
      }
    }
    footer{
      name
      link
    }
  }
}
`