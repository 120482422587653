import React from 'react'
import { Link } from 'gatsby'

class NavMenuTemplate extends React.Component {

  render() { 

    const data = this.props.data;
    const section = this.props.section;
    const active = this.props.active;

    return (
      <div className="nav-menu-portlet">
        <h2 className="nav-menu-portlet__title">{section}</h2>
        <ul className="nav-menu-portlet__list">
          {data.map((item, index) => {
            return <li key={index} className={item.link===active?"nav-menu-portlet__item  nav-menu-portlet__item--active":"nav-menu-portlet__item "}>
                <Link to={item.link} title={item.name} className="nav-menu-portlet__link">{item.name}</Link>
              </li>
          })}

        </ul>
      </div>
    )
  }

}

export default NavMenuTemplate;